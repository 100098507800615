<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content">
      <div class="subtabs" style="height: 2%; background: none">
        <input type="radio" name="subtabs" id="AcademicsTab" checked />
        <label for="AcademicsTab">Academics</label>
        <div class="tab">
          <div class="row" style="padding: 8px">
            <div class="col-lg-12 pr-md-3">
              <div>
                <div class="dispflex" style="justify-content: normal;">
                  <span class="has-float-label" style="width: 10%;margin-right: 29px;">
                    <select
                      @change="getExamList(selectTerm)"
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="selectTerm"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in terms"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="clslistid">Select Term </label>
                  </span>
                  <span class="has-float-label" style="width: 13%">
                    <select
                      @change="getExamAcademicInfo(examInfoId,selectTerm)"
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="examInfoId"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in examList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="clslistid">Select Exam Term </label>
                  </span>

                  
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12 mt-3">
              <div class="tbllist mt-1">
                <div class="restable" style="text-align: center">
                  <div class="resrow resheader">
                    <!-- <div class="cell">Term</div> -->
                    <div class="cell">Exam Term</div>
                    <div class="cell">Exam Date</div>

                    <div class="cell" >Subject</div>
                    <div class="cell">EXAM FOR (MARKS)</div>
                    <div class="cell">MARKS SECURED</div>
                    <div class="cell">Grade</div>
                    <div class="cell">ABSENT/PRESENT</div>
                    <!-- <div class="cell">Action</div> -->
                  </div>
                  <div
                    class="resrow"
                    v-for="(data, index) in examAcademicList"
                    :key="index"
                  >
                    <!-- examAcademicList -->
                    <div class="cell">{{ data.examDetail ? data.examDetail.name : ''}} </div>
                   
                    <div class="cell">
                      {{
                        data.examDate ? data.examDate.substring(0, 10) : "-"
                      }}
                    </div>
                    <div class="col-1 cell">
                      {{ data.subjectName }}
                    </div>
                   

                    <div class="cell"> {{ data.totalMarks || '-' }}</div>
                    <div class="cell" > {{ data.mark }}</div>

                    <div class="cell"  :style="data.grade ? 'black' : 'color: orange'"> {{ data.grade }}</div>
                    <div class="cell"  :style="data.isAbsent ? 'color: #12ed12;' : 'color: orange'"> {{ data.isAbsent  ? data.isAbsent ? 'PRESENT' : 'ABSENT' : '-' }}</div>

                    <!-- <div class="cell"></div> -->
                    <!-- <div class="cell">
                      <button
                        type="button"
                        style="width: 59%;height: 33px;"
                        class="btn btnsml"
                        id="svbtn"
                        @click="
                          openResultPopUp(
                            data._id,
                            data.subject,
                            data.startDate,
                            data.totalMarks
                          )
                        "
                      >
                        View Result
                      </button>
                    </div> -->
                  </div>
                </div>
                <div
                  class="widjet"
                  v-if="examAcademicList.length == 0"
                  style="height: 198px; box-shadow: none"
                >
                  <div style="padding-top: 92px">
                    <span style="margin-left: 42%">No Data Found</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <input type="radio" name="subtabs" id="CurricularActivities" />
        <label for="CurricularActivities">Curricular Activities</label>
        <div class="tab">
          <div class="row" style="padding: 8px">
            <div class="col-lg-12 pr-md-3">
              <div>
                <div class="dispflex" style="justify-content: normal;">
                  <span class="has-float-label" style="width: 10%;margin-right: 29px;">
                    <select
                      @change="getExamList(selectTerm)"
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="selectTerm"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in terms"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="clslistid">Select Term </label>
                  </span>
                  <span class="has-float-label" style="width: 13%">
                    <select
                      @change="getExamAcademicInfo(examInfoId,selectTerm)"
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="examInfoId"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in examList"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="clslistid">Select Exam Term </label>
                  </span>

                  
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12 mt-3">
              <div class="tbllist mt-1">
                <div class="restable" style="text-align: center">
                  <div class="resrow resheader">
                    <!-- <div class="cell">Term</div> -->
                    <div class="cell">Exam Term</div>
                    <div class="cell">Exam Date</div>

                    <div class="cell" >Subject</div>
                    <div class="cell">EXAM FOR (MARKS)</div>
                    <div class="cell">MARKS SECURED</div>
                    <div class="cell">Grade</div>
                    <div class="cell">ABSENT/PRESENT</div>
                    <!-- <div class="cell">Action</div> -->
                  </div>
                  <div
                    class="resrow"
                    v-for="(data, index) in examCurricularList"
                    :key="index"
                  >
                    <!-- examAcademicList -->
                    <div class="cell">{{ data.examDetail ? data.examDetail.name : ''}} </div>
                   
                    <div class="cell">
                      {{
                        data.examDate ? data.examDate.substring(0, 10) : ""
                      }}
                    </div>
                    <div class="col-1 cell">
                      {{ data.subjectName }}
                    </div>
                   

                    <div class="cell"> {{ data.totalMarks || '' }}</div>
                    <div class="cell" > {{ data.mark  }}</div>

                    <div class="cell"  :style="data.grade ? 'black' : 'color: orange'"> {{ data.grade  }}</div>
                    <div class="cell"  :style="data.isAbsent ? 'color: #12ed12;' : 'color: orange'"> {{ data.isAbsent  ? data.isAbsent ? 'PRESENT' : 'ABSENT' : '-' }}</div>

                    <!-- <div class="cell"></div> -->
                    <!-- <div class="cell">
                      <button
                        type="button"
                        style="width: 59%;height: 33px;"
                        class="btn btnsml"
                        id="svbtn"
                        @click="
                          openResultPopUp(
                            data._id,
                            data.subject,
                            data.startDate,
                            data.totalMarks
                          )
                        "
                      >
                        View Result
                      </button>
                    </div> -->
                  </div>
                </div>
                <div
                  class="widjet"
                  v-if="examCurricularList.length == 0"
                  style="height: 198px; box-shadow: none"
                >
                  <div style="padding-top: 92px">
                    <span style="margin-left: 42%">No Data Found</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <input type="radio" name="subtabs" id="Attendance" />
        <label for="Attendance">Attendance</label>
        <div class="tab">
          <div class="row" style="padding: 8px">
            <div class="col-lg-12 pr-md-3">
              <div>
                <div class="dispflex">
                  <span class="has-float-label" style="width: 12%">
                    <select
                      @change="getStudentAttendanceInfo()"
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="selectAttendanceTerm"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in terms"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="clslistid">Select Term </label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12 mt-3 pr-md-3">
              <div class="restable">
                <div class="resrow resheader">
                  <!-- <div class="cell">Student Name</div> -->
                  <div class="cell">Total School Days</div>
                  <div class="cell">Present</div>
                  <div class="cell">Absent</div>
                  <!-- <div class="cell">Grade</div> -->
                  <!-- <div class="cell">Action</div> -->
                </div>
                <div
                  class="resrow"
                  v-for="(data, index) in studentAttendanceList"
                  :key="index"
                >
                  <!-- <div class="cell">{{ data.term }} </div> -->
                  <!-- <div class="cell">
                    {{ data.firstName + " " + data.lastName }}
                  </div> -->
                  <div class="cell" style="padding-left: 45px;">{{ data.student.length }}</div>
                  <div class="cell" style="padding-left: 23px;">{{ getPresentCount(data.student) }}</div>
                  <div class="cell" style="padding-left: 23px;">{{ getAbsentCount(data.student) }}</div>

                  <!-- <div class="cell">
                    <button
                      type="button"
                      style="width: 59%"
                      class="btn btnsml"
                      id="svbtn"
                      @click="openAttendancePopUp(data)"
                    >
                      Edit
                    </button>
                  </div> -->
                </div>
              </div>
              <div
                class="widjet"
                v-if="studentAttendanceList.length == 0"
                style="height: 198px; box-shadow: none"
              >
                <div style="padding-top: 92px">
                  <span style="margin-left: 42%">No Data Found</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <input type="radio" name="subtabs" id="OverallPerformance" />
        <label for="OverallPerformance">Overall Performance</label>
        <div class="tab">
          <div class="row" style="padding: 8px">
            <div class="col-lg-12 pr-md-3">
              <div>
                <div class="dispflex">
                  <span class="has-float-label" style="width: 12%">
                    <select
                      @change="getOverAllPerformanceInfo()"
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="selectPerformanceTerm"
                    >
                      <option :value="null">-- Select --</option>
                      <option
                        v-for="(item, index) in terms"
                        :key="index"
                        :value="item._id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="clslistid">Select Term </label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12 mt-3 pr-md-3">
              <div class="restable">
                <div class="resrow resheader">
                  <!-- <div class="cell">Student Name</div> -->
                  <div class="cell">Academics</div>
                  <div class="cell">Curricular</div>
                  <div class="cell" style="text-align:center;width: 13%;" v-for="(item, index) in editColumnList" :key="index">{{  item.label  }}</div>
                  <div class="cell" style="text-align:center;">Attendance</div>

                  <div class="cell" style="text-align:center">OverAll Grade</div>
                  <div class="cell" style="text-align:center">Rank</div>

                  <!-- <div class="cell">Action</div> -->
                </div>
                <div class="resrow" v-for="(data, index) in Object.keys(overallPerformanceDetails)" :key="index">
                 <!-- {{ data }} -->
                 <!-- <div class="col-2 cell">{{ overallPerformanceDetails[data][0].contact.firstName + " " +  overallPerformanceDetails[data][0].contact.lastName}} </div> -->
                 <div class="col-1 cell">{{ getAcademicStudentMarks(data) > 0 ? getAcademicStudentMarks(data) + " / " + getAcademicTotalMarks(data) : 0 + " / " + getAcademicTotalMarks(data) }} </div>

                 <div class="col-1 cell" >{{ getCurricularStudentMarks(data) > 0 ? getCurricularStudentMarks(data) + " / " + getCurricularTotalMarks(data) : 0 + " / " + getCurricularTotalMarks(data) }} </div>

                 <!-- <div class="col-1 cell" >{{ getCurricularStudentMarks(data) + " / " + getCurricularTotalMarks(data) }} </div> -->
                 <!-- <div class="col-1 cell" v-else>-- </div> -->
                 <div class="cell" style="text-align:center;width: 13%;" v-for="(item, indexcc) in editColumnList" :key="indexcc">{{  getOverallStudentContribution(data,item.value) + " / " + item.totalmark  }}</div>
                 <div class="col-1 cell" style="text-align:center">{{  getStudentAttendance(data)  }} </div>

                 <div class="col-1 cell" style="text-align:center">{{ getOverallStudentGradeWithHeaders(data) }}</div>
                 <div class="col-1 cell" style="text-align:center">{{ studentRankDetails && studentRankDetails.mark ? studentRankDetails.rank : '-' }}</div>

                 <!-- <div class="col-1 cell"></div> -->
                 <!-- <div class="col-1 cell">{{ data }} </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="examResultPopUp" style="width: 100%">
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="dispflex">
             
            </div>

            <div class="tbllist mt-1">
              <div class="restable">
                <div class="resrow resheader">
                  <!-- <div class="cell">Student Name</div> -->
                  <div class="cell">Exam for (Marks)</div>
                  <div class="cell">Marks Secured</div>
                  <div class="cell">Grade</div>

                  <div class="cell" style="text-align: center;">Absent/Present</div>
                  <div class="cell">Comments</div>
                </div>

                <div
                  class="resrow"
                  v-for="(data, index) in saveContactResultList"
                  :key="index"
                >
                  <!-- <div class="cell">{{ data.firstName }}</div> -->
                  <div class="cell"> {{ data.totalMarks || '' }}</div>

                  <div class="cell">{{  data.mark }} </div>
                  <div class="cell">{{ data.grade || '--' }}</div>

                  <div class="cell" style="text-align: center;">{{  data.isAbsent ? 'PRESENT' : 'ABSENT' }} </div>
                  <div class="cell">{{  data.comment }} </div>

                  <!-- <div class="cell">
                    <textarea
                    disabled
                      name="comment"
                      class="form-control form-input"
                      style="min-height: 40px; resize: none"
                      id="tocmts"
                      v-model="data.comment"
                    ></textarea>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="widjetfooter text-right dkicon">
             
              <button
                type="button"
                class="btn btncl clsmdl"
                @click="closeResultPopUp"
              >
                   <span>Back</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
   
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import ViService from "@/services/ViService";
import secureUI from "../../../utils/secureUI";

export default {
  name: "teacher",
  props: {
    classId: {
      type: String,
    },
    classRoom: {
      type: String,
    },
    terms: {
      type: Array,
    },
    accountId: {
      type: String,
    },
    academicYearId: {
      type: String,
    }
  },
  data() {
    return {
      studentRankDetails: {},
      saveContactResultList: [],
      termTotalMarks: null,
      selectPerformanceTerm: null,
      selectAttendanceTerm: null,
      overallPerformanceDetails: {},
      allGradeList: {},
      examAcademicDetails: {
        subject: null,
        startDate: null,
        endDate: null,
        totalMarks: null,
        type: "academic",
      },
      examCurricularDetails: {
        subject: null,
        curricularActivitiesName: "",
        startDate: null,
        totalMarks: null,
        endDate: null,
        type: "curricularActivitie",
      },

      selectTerm: null,
      subjectList: [],
      termList: [],
      examInfoId: null,
      examAcademicList: [],
      examList: [],
      studentContributionList: {},
      editColumnList: [],
      examSubjectCurricularList: [],
      examCurricularList: [],
      studentAttendanceList: [],
      examId: null,
      examStartDate: null,
      examSubjectId: null,
      studentAttendanceDetails: {},
      saveStudentAttendanceDetails: {
        date: new Date(),
        isAbsent: false,
        comment: "",
      },
    };
  },
  created() {
    //this.getSubjectList();
    this.getStudentContributions()
    this.initComponent();
    this.getOverAllPerformanceInfo()
    this.getStudentRankResult()
    this.getContributionHeaders()
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    checkAttendance() {
      if (
        this.studentAttendanceDetails &&
        this.studentAttendanceDetails.student
      ) {
        let details = this.studentAttendanceDetails.student.find(
          (x) => x.date == new Date()
        );
        if (details) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }

      //this.studentAttendanceDetails.student
    },

    getSubjectAll() {
      if (this.subjectList.length > 0) {
        return this.subjectList.filter(
          (x) => x.isCurricularActivities == false
        );
      } else {
        return 0;
      }
    },
    getSubjectCurricularActivities() {
      if (this.subjectList.length > 0) {
        return this.subjectList.filter((x) => x.isCurricularActivities == true);
      } else {
        return [];
      }
    },
  },

  methods: {
    getAbsentCount(data) {
      if (data.length > 0) {
        let details = data.filter((x) => x.status == 'Absent' || ( x.isDailyAttendance && x.status == 'Leave'));
        return details.length;
      } else {
        return 0;
      }
    },
    getPresentCount(data) {
      if (data.length > 0) {
        let details = data.filter((x) => x.status == 'Present');
        return details.length;
      } else {
        return 0;
      }
    },
    getAcademicStudentMarks(value) {
      let getStudentAcademicResult = this.overallPerformanceDetails[value]

      if(getStudentAcademicResult.length > 0){
            let filterExamType = getStudentAcademicResult.filter( x => x.isCurricularActivities == false && x.isReport)

            if (filterExamType.length > 0) {
            return filterExamType.map((o) => o.mark)
              .reduce((a, c) => {
                return a + c;
              });
          } else {
            return '';
          }
      }else{
        return ''
      }
      
    },
    getStudentTotalAttendance(value) {
     // percentage : x.mark ? (Number(x.mark) * (data.totalMarks)) / 100  : null,


     // let getStudentAcademicResult = this.overallPerformanceDetails[value]
     let getStudentAcademicResult = this.studentAttendanceList.filter( x => x._id == value)[0]

      if(getStudentAcademicResult && getStudentAcademicResult.student && getStudentAcademicResult.student.length > 0){
        return getStudentAcademicResult.student.length
        
      }else{
        return ''
      }
      
    },
    getStudentAttendance(value){

      let getStudentAcademicResult = this.studentAttendanceList.filter( x => x._id == value)[0]
    
      if(getStudentAcademicResult && getStudentAcademicResult.student && getStudentAcademicResult.student.length > 0){

        let presentData = getStudentAcademicResult.student.filter((x) => x.status == 'Present');

        let count = presentData.length
        let dd  = ((count/(getStudentAcademicResult.student.length)) * 100).toFixed(2)
        return Math.round(dd) + ' ' + '%'
      }else{
        return '0 %'
      }
      
    },
    getOverallStudentGradeWithHeaders(value){
     
     let studentTotalMarks = 0
     let examTotalMarks = 0

     let getStudentAcademicResult = this.overallPerformanceDetails[value]

     if(getStudentAcademicResult.length > 0){

          let filterExamType = getStudentAcademicResult.filter( x => x.isExamEvaluated && x.isReport)

          if(filterExamType.length > 0){

           examTotalMarks = filterExamType.map((o) => Number(o.totalMarks)).reduce((a, c) => { return a + c; });
           studentTotalMarks = filterExamType.map((o) => o.mark).reduce((a, c) => { return a + c; });

          }

     }

    if(this.editColumnList.length > 0){
      let details = this.studentContributionList

      if(details){
        for(let x of this.editColumnList){
          let mark = details[x.value] || 0
          if(mark){
            studentTotalMarks = studentTotalMarks + Number(mark)
          }
          if(x.totalmark){
            examTotalMarks = examTotalMarks + x.totalmark
          }
         

        }

      }
    }

    let avg  = ((studentTotalMarks/(examTotalMarks)) * 100).toFixed(2)

    let roundOfAvg = Math.round(avg)

    const filteredStudents = this.allGradeList.gradeMarkList.filter(x => roundOfAvg >= x.from && roundOfAvg <= x.to);
    
    if(filteredStudents.length > 0){
     return filteredStudents[0].grade
    }else{
     return '-'
    }
  
   },
    getAcademicTotalMarks(value) {
      let getStudentAcademicResult = this.overallPerformanceDetails[value]

      if(getStudentAcademicResult.length > 0){
            let filterExamType = getStudentAcademicResult.filter( x => x.isCurricularActivities == false && x.isReport)

            if (filterExamType.length > 0) {
            return filterExamType.map((o) => Number(o.totalMarks))
              .reduce((a, c) => {
                return a + c;
              });
          } else {
            return '';
          }
      }else{
        return ''
      }
      
    },
    getCurricularStudentMarks(value) {
      let getStudentAcademicResult = this.overallPerformanceDetails[value]

      if(getStudentAcademicResult.length > 0){
            let filterExamType = getStudentAcademicResult.filter( x => x.isCurricularActivities && x.isReport)

            if (filterExamType.length > 0) {
            return filterExamType.map((o) => o.mark)
              .reduce((a, c) => {
                return a + c;
              });
          } else {
            return '';
          }
      }else{
        return ''
      }
      
    },
    getCurricularTotalMarks(value) {
      let getStudentAcademicResult = this.overallPerformanceDetails[value]

      if(getStudentAcademicResult.length > 0){
            let filterExamType = getStudentAcademicResult.filter( x => x.isCurricularActivities && x.isReport)

            if (filterExamType.length > 0) {
            return filterExamType.map((o) => Number(o.totalMarks))
              .reduce((a, c) => {
                return a + c;
              });
          } else {
            return '';
          }
      }else{
        return ''
      }
      
    },
    getOverallStudentContribution(studentId,data){
   //  let details = this.studentContributionList.find( x => x.studentId == studentId)
     if(this.studentContributionList && this.studentContributionList[data]){
      return this.studentContributionList[data] || 0
     }else{
      return 0
     }
    },
    async initComponent(){
      await this.initComponentView()
    },

    initComponentView(){
     
     if (this.terms.length > 0) {
            if (this.terms.length == 1) {
            this.selectTerm = this.terms[0]._id;
            this.getExamList(this.selectTerm);
            this.selectPerformanceTerm = this.selectTerm
            this.selectAttendanceTerm = this.terms[0]._id;
            this.getOverAllPerformanceInfo()
            this.getStudentAttendanceInfo();

          
            }else{
            let currentDate = new Date()

            let checkData = this.terms.filter( x => (currentDate >= new Date(x.startDate)) && (currentDate <= new Date(x.endDate)))
            if(checkData.length > 0){

              this.selectTerm = checkData[0]._id;
              this.getExamList(this.selectTerm);
              this.selectPerformanceTerm = this.selectTerm
              this.selectAttendanceTerm = this.terms[0]._id;

              this.getOverAllPerformanceInfo()
              this.getStudentAttendanceInfo();


             // this.getCurriculumInfo(checkData[0]._id,null);


            }else{
              let checkfutureDates = this.terms.filter( x => (new Date(x.startDate) > currentDate))
             if(checkfutureDates.length > 0){
              this.selectTerm = checkfutureDates[0]._id;
              this.getExamList(this.selectTerm);
              this.selectPerformanceTerm = this.selectTerm
              this.selectAttendanceTerm = this.terms[0]._id;

              this.getOverAllPerformanceInfo()
              this.getStudentAttendanceInfo();


             // this.getCurriculumInfo(checkfutureDates[0]._id,null);

             }else{
              this.selectTerm = this.terms[0]._id;
              this.getExamList(this.selectTerm);
              this.selectPerformanceTerm = this.selectTerm
              this.selectAttendanceTerm = this.terms[0]._id;

              this.getOverAllPerformanceInfo()
              this.getStudentAttendanceInfo();


              //this.getCurriculumInfo(this.terms[0]._id,null);
            }
            }
           
            }
          }
    },
    async getContributionHeaders() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        let obj = {
            accountId: this.accountId,
            term: this.selectPerformanceTerm,
           // studentId: this.$route.params.id
            class: this.classId
        }
       
        const response = await ViService.viXPost(
          "/academic/getContributionHeaders",
          obj,
          userData.token
        );

        if (response.isSuccess) {
          this.editColumnList = []

          this.$toasted.success(response.message);
          
          if(response.data.length > 0){
             this.editColumnList = response.data
          }
        } else {
          this.$toasted.error(response.message);
        }
    }
    },
    async getStudentContributions() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

        let obj = {
            accountId: this.accountId,
            studentId: this.$route.params.id,
            class: this.classId
        }
        
       
        const response = await ViService.viXPost(
          "/academic/getStudentViewContributions",
          obj,
          userData.token
        );

        if (response.isSuccess) {
         
          this.allGradeList = response.data.gradeSetup

          this.studentContributionList = response.data.contributions
        } else {
          this.$toasted.error(response.message);
        }
    }
    },
    async getExamList(id) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        if (id) {
          this.examAcademicList = []
          this.examCurricularList = []

          let requestBody = {
            class: this.classId,
            term: id,
          };

          const response = await ViService.viXPost(
            "/academic/getStudentExamScheduleInfo",
            requestBody,
            userData.token
          );

          if (response.isSuccess) {
            const resultData = secureUI.secureGet(response.data);
            
            this.examList = resultData;
            if(this.examList.length > 0){
              
              let details = this.examList[this.examList.length - 1]

              if(details){
                this.examInfoId = details._id
                this.getExamAcademicInfo(this.examInfoId,id)
              }
             // this.examInfoId
            }
           // examInfoId
          
          } else {
            this.$toasted.error(response.message);
          }
        } else {
          (this.examAcademicList = []), (this.examCurricularList = []);
        }
      }
    },
    async getExamAcademicInfo(examId,id) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        if (id) {
          let requestBody = {
            class: this.classId,
            term: id,
            examId: examId,
            academicYear: this.academicYearId,
            accountId: this.accountId,
            studentId: this.$route.params.id
          };

          const response = await ViService.viXPost(
            "/academic/getStudentViewExamAcademicInfo",
            requestBody,
            userData.token
          );

          if (response.isSuccess) {
            const resultData = secureUI.secureGet(response.data);
            
            this.examAcademicList = resultData.filter( x => x.isCurricularActivities == false)
            this.examCurricularList = resultData.filter( x => x.isCurricularActivities)

           // this.examAcademicList = resultData
            // this.examCurricularList = resultData.filter(
            //   (x) => x.type != "academic"
            // );
          } else {
            this.$toasted.error(response.message);
          }
        } else {
          (this.examAcademicList = []), (this.examCurricularList = []);
        }
      }
    },
    async getStudentAttendanceInfo() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let requestBody = {
          class: this.classId,
          classroom: this.classRoom,
          term: this.selectAttendanceTerm,
          academicYear: this.academicYearId,
          accountId: this.accountId,
          studentId: this.$route.params.id
        };

        const response = await ViService.viXPost(
          "/academic/studdentview/getStudentAttendanceInfo",
          requestBody,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          
          this.studentAttendanceList = resultData;
        } else {
          this.$toasted.error(response.message);
        }
      }
    },

    async getExamAcademicStudentInfo() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let requestBody = {
          term: this.selectTerm,
          class: this.classId,
          classroom: this.classRoom,
          exam: this.examId,
          academicYear: this.academicYearId,
          accountId: this.accountId,
          studentId: this.$route.params.id
        };

        const response = await ViService.viXPost(
          "/academic/getExamResultStudentInfo",
          requestBody,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          this.saveContactResultList = resultData
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    
    async getOverAllPerformanceInfo() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let requestBody = {
          term: this.selectPerformanceTerm,
          class: this.classId,
          classroom: this.classRoom,
          academicYear: this.academicYearId,
          accountId: this.accountId,
          studentId: this.$route.params.id
        };

        const response = await ViService.viXPost(
          "/academic/getStudentViewOverAllPerformanceResult",
          requestBody,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);

          this.overallPerformanceDetails = resultData
         
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
    async getStudentRankResult() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let requestBody = {
          term: this.selectPerformanceTerm,
          class: this.classId,
          classroom: this.classRoom,
          academicYear: this.academicYearId,
          accountId: this.accountId,
          studentId: this.$route.params.id
        };

        const response = await ViService.viXPost(
          "/academic/getStudentOverAllRankResult",
          requestBody,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);

          this.studentRankDetails = resultData
         
        } else {
          this.$toasted.error(response.message);
        }
      }
    },
   


    openAcademicPopUp() {
      this.$bvModal.show("addAcademicPopUp");
    },
    closeAcademicPopUp() {
      this.$bvModal.hide("addAcademicPopUp");
      this.examAcademicDetails = {
        subject: null,
        startDate: null,
        totalMarks: null,
        endDate: null,
        type: "academic",
      };
    },
    openCurricularPopUp() {
      this.$bvModal.show("addCurricularPopUp");
    },
    closeCurricularPopUp() {
      this.$bvModal.hide("addCurricularPopUp");
      this.examCurricularDetails = {
        subject: null,
        curricularActivitiesName: "",
        startDate: null,
        totalMarks: null,
        endDate: null,
        type: "curricularActivitie",
      };
    },
    openAttendancePopUp(data) {
      let currentDate = new Date();

      let startmonth = currentDate.getMonth() + 1;
      let startdate = currentDate.getDate();

      if (startdate < 10) {
        startdate = "0" + startdate;
      }
      if (startmonth < 10) {
        startmonth = "0" + startmonth;
      }

      this.saveStudentAttendanceDetails.date =
        currentDate.getFullYear() + "-" + startmonth + "-" + startdate;

      this.studentAttendanceDetails = data;
      this.$bvModal.show("addAttendancePopUp");
    },
    closeAttendancePopUp() {
      this.$bvModal.hide("addAttendancePopUp");
    },

    openResultPopUp(id, subId, date,mark) {
      this.termTotalMarks = null
      this.termTotalMarks = mark
      this.examId = id;
      (this.examSubjectId = subId),
      (this.examStartDate = date),
      this.$bvModal.show("examResultPopUp");
      this.getExamAcademicStudentInfo();
    },
    closeResultPopUp() {
      this.termTotalMarks = null
      this.examId = null;
      (this.examStartDate = null), (this.examSubjectId = null);
      this.$bvModal.hide("examResultPopUp");
      this.saveContactResultList = [];
    },
  },
};
</script>
